.team {
  width: 100%;
   overflow-x: hidden;
   padding-left: 5%;
   padding-right: 5%;
   text-align: justify;

}

.teampic{


}

.teamimg{
  width: 80%;
  display: block;
 margin-left: auto;
 margin-right: auto;
}

.teampara{
  display: block;

    font-size: clamp(15px, 1.2vw, 30px);
    font-family: 'Barlow', sans-serif;

}

.teamrow{
  padding-top: 3%;
}

.teamhead{
  display: block;

  color: #00BFFF;
    font-size: clamp(20px, 4vw, 30px);
}
.teambutton{
  display: block;

}

.team-para{

  text-align: justify;
  margin-top: 3%;
}

.acquire{
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 2rem;
  margin-top: 1rem;
  margin-bottom: 2rem;

  background-image: url("./images/wall.jpeg");
    background-size: cover;
}

.acquirehead{
  font-size: clamp(25px, 4vw, 40px);
  color: #00BFFF;
  text-align: center;
  padding-bottom: 1rem;
  font-family: 'Oxanium', cursive;
}

.acquire-item{
  text-align: center;
  padding: 2%;
  margin-bottom: 2rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border-style: solid;
background-color: 	slategray;
}

.acquirepara{

padding: 4%;
  margin-bottom: 1rem;

  color: white;
  font-size: clamp(20px, 3.5vw, 20px);
  font-family: 'Barlow', sans-serif;
}

.acquire-foot{
    font-size: clamp(20px, 3.5vw, 20px);
    text-align: center;
    color: #00BFFF;
    font-weight: bold;
    font-family: 'Oxanium', cursive;
}
