.about{
  padding-top: 2rem;
  padding-left: 5%;
  padding-right: 5%;
  width: 100%;
   overflow-x: hidden;
     padding-bottom: 2rem;
}

.abouthead{
  padding-bottom: 1rem;
  color: #00BFFF;
    font-size: clamp(20px, 4vw, 30px);
    font-family: 'Oxanium', cursive;

}

.aboutsec{
  background-color: 	lightsteelblue;
  background-clip: border-box;
  padding: 5%;
}


.content1{
  display: block;
  left: auto;
  right: auto;
  text-align: center;
  padding-top: 2rem;

}
.content1 > img{
  width: 50%;
  display: block;
margin-left: auto;
margin-right: auto;

}


.conthead{
    font-size: clamp(20px, 3.5vw, 20px);
    font-family: 'Oxanium', cursive;

}

.aboutpara1 {
  font-size: clamp(15px, 1.2vw, 30px);
  font-family: 'Barlow', sans-serif;
}

.partimg{
  padding-top: 2rem;
  width: 90%;
  max-width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;

}

.aboutsec{
margin-top: 3%;
margin-left: 5%;
margin-right: 5%;
}
