*{
  margin:0;
  padding:0;
  font-family:verdana;

}
.navbar3{

  width:100%;

  background-image: url("./images/coding.jpg");
  background-size: cover;

width: 100%;
 overflow-x: hidden;

}
.nav2{
  width: 100%;
  height: 50px;
  background-color: #0003;
  line-height: 50px;

}
.navlink2 ul{
  float: right;

}
.nav2 ul li{
  list-style-type: none;
  display: inline-block;
  transition: 1s all;
}
.nav2 ul li:hover{
 transform: scale(1.5);
 text-decoration: none;
 text-shadow: none;
text-decoration-color: none;
}
ul li a{
  text-decoration: none;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
}

a:active{
color: black
}

ul:hover li:hover a:hover {
  COLOR: white; TEXT-DECORATION: none; font-weight: none
}
.intro{
  padding-top: 10rem;
  margin-left: 10%;
}
.introhead{
  color: white;
font-size: clamp(30px, 3vw, 50px);
font-weight: bold;
}

.introbutton{
  background-color: aliceblue;
  margin-top: 3rem;
  padding: 1%;
  border-style: none;
  font-size: clamp(10px, 3vw, 20px);
}
.navlink2{
  font-size: clamp(5px, 3vw, 20px);

}

.logo{
  width: auto;
  height: 40px;
  display: inline;
  padding-top: 0px;
  padding-left: 5%;
}

.ul{
  display: inline;
  position: absolute;
  right: 10%;
  text-align: right;
}
