body {
  height: 100%;
   width: 100%;
   margin: 0;
   padding: 0;
   overflow-x: hidden;
}
.home-para{
    font-size: clamp(15px, 1.2vw, 30px);
  margin-left: 10%;
  margin-right: 10%;
    font-family: 'Barlow', sans-serif;
       margin-left: 10%;
       margin-right: 10%;
       font-weight: 50;
       padding-top: 4%;
       text-align: justify;
}
.acquire-item:hover{
  transform: scale(1.5);
}
