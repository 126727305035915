.contact {
width: 80%;
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 1rem;
background-color: 	#EBEBEB;

}
.contact-main {
  padding-top: 4%;
  width: 100%;
   overflow-x: hidden;

}
.contactimg{
width: 100%;
}
.contact-con{
  padding-top: 3rem;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 3rem;
}
.contact-head{
text-align: center;
color: #00BFFF;
  font-size: clamp(20px, 4vw, 30px);
  font-weight: bold;
  font-family: 'Oxanium', cursive;
}
.contact-head1{
  font-weight: bold;
    font-size: clamp(25px, 3vw, 30px);
    font-family: 'Oxanium', cursive;
}
.first{
  display: inline-block;
  margin-right: 10%;
  width: 45%;
  margin-bottom: 4%;
}

.second{
  display: inline-block;
  width: 45%;
    margin-bottom: 4%;
}

.contact-para{
  display: block;
  text-align: justify;
    font-size: clamp(10px, 2.5vw, 30px);
    font-family: 'Barlow', sans-serif;
}
.email{
  width: 100%;
    margin-bottom: 4%;
}

.message{
  width: 100%;
  height: 200px;
      margin-bottom: 4%;
}

.contact-button {
  background-color: black;
  color: #EBEBEB;
  padding: 0.5rem;
}
.find{
  margin-top: 3rem;
 background-image: url("./images/map.jpg");
 padding-top: 2rem;
 padding-bottom: 8rem;
 background-size: cover;

}

.find-head{
  text-align: center;
  color: white;
  font-weight: bold;
   margin-bottom: 3rem;
   font-family: 'Oxanium', cursive;
}
.map{
  display: block;
  margin-left: auto;
  margin-right: auto;
 width: 300px;
 height: 200px;
 border-style: solid;
 border-width: medium;
}


.modalreg {
  background: #2A2B2D;
box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.05);

}

.register-form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom: 5%;
}
.warning {
  color: red;
}
.success-message {
  font-family: "Roboto", sans-serif;
  background: linear-gradient(74.87deg, #0DC789 23.62%, #89CE69 78.59%);
  padding: 10px;
  color: white;
  margin-bottom: 2%;
}
