.serveimg{
width: 80%;
display: block;
margin-left: auto;
margin-right: auto;
}
.serveimg3{
  width: 100%;
  background-color: lightsteelblue;
  margin-top: 2%;
  padding-top: 2%;
}

.clientimg{
  width: 100%;
}
.clienthead{
  text-align: center;
  font-weight: bold;
    color: navy;
    filter:blur("2%");
    font-family: 'Oxanium', cursive;
}


.serve {
  width: 100%;
   overflow-x: hidden;
}

.serve-para{
  display: block;
  text-align: justify;
  font-size: clamp(10px, 3vw, 20px);
  margin-left: 10%;
  margin-right: 10%;
    font-family: 'Barlow', sans-serif;
}
.serve-ind{
font-family: 'Oxanium', cursive;
  color: #00BFFF;
    font-size: clamp(20px, 4vw, 30px);
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2%;
    font-weight: bold;
}
